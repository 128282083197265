
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import * as smartsupp from 'smartsupp-widget';

import PromoCard from '@/components/promos/PromoCard.vue';
import HeaderImage from '@/components/dashboard/HeaderImage.vue';
import { Actions, Getters } from '@/store/enums/PromoEnums';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';

import DrawLoaderCard from '@/components/dashboard/DrawLoaderCard.vue';

export default defineComponent({
  name: 'agency-list',
  components: {
    HeaderImage,
    PromoCard,
    DrawLoaderCard,
  },
  data: () => ({
    loading: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      promos: Getters.GET_PROMOS,
      authUser: AuthGetters.GET_AUTH_USER,
    }),
  },
  methods: {
    ...mapActions({
      fetchPromos: Actions.FETCH_PROMOS,
    }),
    async init() {
      this.loading = true;

      await this.fetchPromos()
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    handleToggleChat() {
      this.handleSmartSuppName();

      smartsupp.chatOpen();
      return false;
    },

    handleSmartSuppName() {
      const firstName = this.authUser.first_name
        ? this.authUser.first_name
        : '';
      const lastName = this.authUser.last_name ? this.authUser.last_name : '';
      const email = this.authUser.email ? this.authUser.email : '';
      const mobile = this.authUser.mobile ? this.authUser.mobile : '';

      const name = firstName && lastName ? `${firstName} ${lastName}` : '';

      smartsupp.setName(name);
      smartsupp.setEmail(email);
      smartsupp.setPhone(mobile);
    },
  },
});
