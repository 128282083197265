
import { defineComponent } from 'vue';
import { ElMessage } from 'element-plus';

import { Promo } from '@/models/PromoModel';

import Chip from '@/components/Chip.vue';
import { Picture as IconPicture } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'promo-card',
  props: {
    promo: {
      type: Object,
      default: () => {
        return {} as unknown as Promo;
      },
    },
    cardClass: {
      type: String,
    },
  },
  components: {
    Chip,
    IconPicture,
  },
  data: () => ({
    readMoreActivated: [] as any,
  }),
  computed: {
    currencyText() {
      return this.promo.currency ? this.promo.currency : 'AUD';
    },

    amountOffText() {
      return this.promo.amount_off ? this.promo.amount_off : '';
    },
    promoFigure() {
      return this.promo.percent_off
        ? `${this.promo.percent_off}% OFF`
        : `${this.currencyText} ${this.amountOffText} OFF`;
    },
  },
  methods: {
    activateReadMore(id) {
      this.readMoreActivated[id] = (!this.readMoreActivated[id] ??
        this.readMoreActivated[id]) as any;
    },

    isReadMore(id) {
      return typeof this.readMoreActivated[id] !== 'undefined'
        ? this.readMoreActivated[id]
        : false;
    },

    toggleCTALabel(id) {
      return typeof this.readMoreActivated[id] !== 'undefined'
        ? ' See Less'
        : ' See More';
    },

    getDescription(size = 0) {
      const descriptionValue = this.promo.description
        ? this.promo.description
        : '';
      const descStrLen = descriptionValue.length;
      const slice = size > 0 ? size : descStrLen;

      return descriptionValue.slice(0, slice);
    },
    handleCopyToClipboard(promo) {
      navigator.clipboard
        .writeText(promo.code)
        .then(() => {
          ElMessage({
            message: 'Copied to clipboard!',
            type: 'success',
          });
        })
        .catch(() => {
          ElMessage.error('Oops, unable to copy to clipboard');
        });
    },
  },
});
